import React from "react";
import { HomePageWrapper } from "./style";
import tryThiswayLogo from "../../assets/trythisway.png";
import { PrimaryButton } from "../../components/theme-components";
import { CalendarOutlined, UnlockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import demoVideo from "../../assets/demo_video.mp4"
const Home = () => {
  const navigate = useNavigate();

  const handleRequestAccess = () => {
    navigate(`signup/${process.env.REACT_APP_LANDING_PAGE_CAMPAIGN_ID}`);
  };

  return (
    <HomePageWrapper>
      <div>
        <img src={tryThiswayLogo} alt="" />
        <p>Over 169 Million Candidates at Your Fingertips</p>
      </div>
      <div >
        <video width="530" height="290"  controls>
          <source
            src={demoVideo}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    
      <div>
        <PrimaryButton
          className="primary-button"
          onClick={() => handleRequestAccess()}
        >
          <div className="primary-button-container">
            <div className="icon">
              <UnlockOutlined style={{ fontSize: "15px" }} />
            </div>
            <div>Request Access</div>
            <div className="arrow">&#8594;</div>
          </div>
        </PrimaryButton>
        <div>
          <span style={{ color: " #a2a2a2" }}>OR</span>
        </div>
        <div
          className="text-link"
          onClick={() =>
            window.open(
              "https://meetings.hubspot.com/thisway-global/demo-request",
              "_blank"
            )
          }
        >
          <div>
            <CalendarOutlined style={{ fontSize: "15px" }} />
          </div>
          <div>Schedule a Meeting</div>
        </div>
      </div>
    </HomePageWrapper>
  );
};

export default Home;
